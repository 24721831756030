@import '~antd/dist/antd.css';
::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

::-webkit-scrollbar-thumb {
    background: #B3AFB3;
    border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
    background: #B3AFB3;
}

::-webkit-scrollbar-track {
    background: #F0F0F0;
    border-radius: 0;
    box-shadow: inset 0 0 0 0 #F0F0F0;
}

.site-root {
    min-height: 100vh;
}

.logo {
    width: 100px;
    height: 100px;
    background-image: url('../resources/images/logo-odianafolia.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
    display: block;
    margin: 0 auto 5px;
    transition: 0.5s;
}

.ant-layout-sider-collapsed .logo {
    width: 50px;
    height: 50px;
}

.ant-page-header {
    padding: 0;
}

.card-signin {
    border-radius: 11px;
    border: solid 5px #F7B801;
    box-shadow: 0px 0px 25px #000000;
}

.bg-signin {
    background-image: url('../resources/images/bg-ig-carnaval-admin.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.card-category-item .ant-card-body {
    padding: 0;
}

.spin-container {
    text-align: center;
    height: 100vh;
    justify-content: center;
    child-align: middle;
}

.spin-container .ant-spin {
    position: absolute;
    top: 50%;
}


@media (max-width: 768px) {
    .ant-layout.ant-layout-has-sider{
        flex-direction: column;
    }
    .ant-layout-sider-has-trigger {
        padding-bottom: 15px;
        min-width: auto !important;
        max-width: none !important;
        width: 100% !important;
    }
    
    .container-m{
        padding: 15px !important;
        box-sizing: border-box;
    }

    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
        padding: 15px 15px !important;
    }

    .ant-space.ant-space-horizontal{
        gap: 10px !important;
    }
}